import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/api/manage/banner/list`,
    method: 'get',
    params
  })
}

export function delAPI(id) {
  return http({
    url: `/api/manage/banner/del`,
    method: 'post',
    data: { id }
  })
}
export function addAPI(data) {
  return http({
    url: `/api/manage/banner/add`,
    method: 'post',
    data
  })
}

export function editAPI(data) {
  return http({
    url: `/api/manage/banner/edit`,
    method: 'post',
    data
  })
}

export function getDetailAPI(id) {
  return http({
    url: `/api/manage/banner/detail`,
    method: 'get',
    params: { id }
  })
}
export function changeStatusAPI(data) {
  return http({
    url: `/api/manage/banner/changeStatus`,
    method: 'post',
    data
  })
}
