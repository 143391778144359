<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>

    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="banner" prop="banner_url" :rules="rules">
        <UploaderImg :url.sync="formData.banner_url"></UploaderImg>
      </el-form-item>

      <el-form-item class="editor" label="详情">
        <Editor :content.sync="formData.content" :showBtn="false"></Editor>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="formData.status" placeholder="请选择" size="small">
          <el-option label="待审核" value="0"> </el-option>
          <el-option label="下架" value="10"> </el-option>
          <el-option label="发布" value="20"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="formData.sort" size="small" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label=" " label-width="100px">
        <el-button size="small" type="" @click="$router.back()">取消</el-button>
        <el-button size="small" type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
    <div style="height: 40px"></div>
  </div>
</template>

<script>
import { addAPI, editAPI, getDetailAPI } from './api'
import UploaderImg from '@/components/qiniu-uploader/uploader-img.vue'
import Editor from '@/components/editor/index.vue'
export default {
  name: 'AddOrEdit',
  components: {
    UploaderImg,
    Editor
  },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        banner_url: '',
        content: 'aaa',
        status: '20',
        sort: 1
      }
    }
  },

  mounted() {
    if (this.$route.params.id) this.getDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          console.log(this.formData)
          if (this.formData.id) {
            // 编辑
            await editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            // 新增
            await addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        } else {
          return false
        }
      })
    },
    async getDetail() {
      this.formData = await getDetailAPI(this.$route.params.id)
      if (this.formData !== null && this.formData !== undefined) {
        this.formData.status = String(this.formData.status);
      }
      console.log("🚀 ~ getDetail ~ this.formData:", this.formData)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 500px;

        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }
    .editor {
      .el-form-item__content {
        width: 1000px;

        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }

    .course-editor {
      .el-form-item__content {
        width: 700px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
